import { TableCell, type SxProps } from '@mui/material'
import type { SubscriptionSummary } from '@orus.eu/backend/src/views/pending-subscriptions-view'
import { useParams, type NavigateOptions } from '@tanstack/react-router'
import { formatDateTime } from '../../lib/format'
import { usePermissions } from '../../lib/use-permissions'
import { ActivableProductInformationIdsBadgesList } from '../molecules/activable-product-information-ids-badges-list'
import { TableCellHeader } from '../molecules/table-elements/table-cell-header'
import { TableRowLink } from '../molecules/table-elements/table-row-link'
import { BackofficeTable } from './backoffice-table'
import { ChipOrganizations } from './chip-organisations'

type BackofficePendingSubscriptionsTableProps = {
  subscriptions: Array<SubscriptionSummary>
  sx?: SxProps
}

export function BackofficePendingSubscriptionsTable({
  subscriptions,
  sx = {},
}: BackofficePendingSubscriptionsTableProps): JSX.Element {
  const isPlatform = usePermissions().type === 'platform'
  return (
    <BackofficeTable
      headers={
        <>
          <TableCellHeader>Date</TableCellHeader>
          <TableCellHeader>Email</TableCellHeader>
          <TableCellHeader>Produits</TableCellHeader>
          {isPlatform && <TableCellHeader>Apporteur d’affaires</TableCellHeader>}
          <TableCellHeader>Étape</TableCellHeader>
        </>
      }
      rows={subscriptions.map((subscription) => (
        <PendingSubscriptionRow key={subscription.id} subscription={subscription} />
      ))}
      sx={sx}
    />
  )
}

type PendingSubscriptionRowProps = {
  subscription: SubscriptionSummary
}

function PendingSubscriptionRow({ subscription }: PendingSubscriptionRowProps) {
  const isPlatform = usePermissions().type === 'platform'
  const { organization } = useParams({ strict: false })

  const link: NavigateOptions = isPlatform
    ? { to: '/bak/v2-pending-subscriptions/$subscriptionId', params: { subscriptionId: subscription.id } }
    : {
        to: '/partner/$organization/v2-pending-subscriptions/$subscriptionId',
        params: { organization: organization ?? '', subscriptionId: subscription.id },
      }

  return (
    <TableRowLink href={link} data-testid={`subscription-list-row-${subscription.email}`}>
      <TableCell>{formatDateTime(subscription.creationTimestamp)}</TableCell>
      <TableCell>{subscription.email}</TableCell>
      <TableCell>
        <ActivableProductInformationIdsBadgesList
          activableProductInformationIds={subscription.activableProductInformationIds}
        />
      </TableCell>
      {isPlatform && (
        <TableCell>
          <ChipOrganizations organizations={[subscription.organization]} />
        </TableCell>
      )}
      <TableCell>{subscription.currentStep}</TableCell>
    </TableRowLink>
  )
}
